:root {
  background-color: rgb(0 0 0);
  height: 100dvh;
  overflow: hidden;
  width: 100dvw;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color--default);
  cursor: var(--cursor--default);
  font-family: var(--font-family--default);
  inset: 0;
  margin: 0;
  overflow: hidden;
  position: fixed;
}

*,
*::before,
*::after {
  border: 0 solid var(--border-color--default);
  box-sizing: border-box;
}

:any-link {
  cursor: var(--cursor--pointer);
}

button {
  appearance: none;
  background: none;
  border-width: 0;
  color: inherit;
  cursor: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  user-select: none;
}

code,
pre {
  font-family: var(--font-family--monospace);
}

label {
  cursor: inherit;
}

.visually-hidden {
  border-width: 0 !important;
  bottom: unset !important;
  box-shadow: none !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  left: 0 !important;
  outline-width: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important;
  right: unset !important;
  top: 0 !important;
  white-space: nowrap !important;
  width: 1px !important;
}
